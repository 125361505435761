<template>
  <main>
    <div class="layout-subcontainer">
      <div class="box-wrap d-flex">
        <div class="col-6 pa-0">
          <h1 class="tit-h1 d-flex">
            약관 관리
            <!-- 조회 버튼 -->
            <div class="ml-auto align-self-center">
              <v-btn class="btn-etc2" outlined small @click="getTermList">조회</v-btn>
            </div>
          </h1>
          <!-- 조회 -->
          <div class="box-ct mt-2">
            <div class="table-form d-flex">
              <table>
                <caption class="hide">
                  조회 항목
                </caption>
                <colgroup>
                  <col width="130px" />
                  <col width="180px" />
                  <col width="130px" />
                  <col width="430px" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="row">
                      건설사명
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-autocomplete
                          v-model="CTCP_CODE"
                          :items="dropCtcpCd"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          hide-details
                          hide-no-data
                          placeholder="선택하세요"
                          @change="changeBussCode(CTCP_CODE)"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                    <th scope="row">
                      사업지명
                    </th>
                    <td>
                      <div class="form-inp full">
                        <v-autocomplete
                          v-model="BUSS_CD"
                          :items="dropBussCd"
                          item-text="CD_NM"
                          item-value="CD"
                          outlined
                          hide-details
                          hide-no-data
                          placeholder="선택하세요"
                          :disabled="dropBussCd.length === 0 ? true : false"
                        >
                        </v-autocomplete>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      약관제목
                    </th>
                    <td colspan="3">
                      <v-text-field
                        class="form-inp full"
                        v-model="TERM_TITLE_SEARCH"
                        name="TERM_TITLE_SEARCH"
                        outlined
                        hide-details
                        placeholder="약관제목"
                        @keyup.enter="getTermList"
                      >
                      </v-text-field>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- 목록 -->
          <div class="box-ct mt-3">
            <h1 class="tit-h1 d-flex">
              약관 목록
              <div class="ml-auto">
                <v-btn 
                  outlined 
                  small
                  class="btn-default ml-2" 
                  @click="downloadExcel('약관 목록', '약관관리')" 
                  :disabled="excelBtn"
                >엑셀다운로드</v-btn>
              </div>
            </h1>
            <div class="mt-2">
              <v-data-table
                dense
                v-model="selectedTermGridDataList"
                :headers="termGridDataHeaders"
                :items="termGridDataText"
                :items-per-page="itemsPerPage"
                item-key="TERM_ID"
                :item-class="isActiveTermRow"
                :page.sync="page"
                hide-default-footer
                @page-count="pageCount = $event"
                fixed-header
                class="grid-default"
                height="570px"
                no-data-text="검색된 결과가 없습니다."
                @click:row="termGridRowClick"
              >
              </v-data-table>
              <div class="grid-paging text-center pt-2">
                <span class="grid-total-count">총 <strong>{{ termGridDataText.length }}</strong>건</span>
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  :total-visible="totalVisible"
                  next-icon="svg-paging-next"
                  prev-icon="svg-paging-prev"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- 상세 -->
        <div class="ml-6 flex-grow-1">
          <h1 class="tit-h1 d-flex">
            약관 상세내용
            <!-- 신규 버튼, 삭제 버튼, 저장 버튼 -->
            <div class="ml-auto align-self-center">
              <v-btn outlined small class="btn-etc" @click="copyInfo" :disabled="copyBtn">복사</v-btn>
              <v-btn outlined small class="btn-default ml-2" @click="newAdd">{{btnName}}</v-btn>
              <v-btn outlined small class="btn-etc ml-2" @click="delInfo" :disabled="delBtn">삭제</v-btn>
              <v-btn outlined small class="btn-point ml-2" @click="newBtnSave" :disabled="saveBtn">저장</v-btn>
            </div>
          </h1>
          <div class="box-ct mt-2">
            <v-form ref="form" lazy-validation>
              <div class="table-form">
                <table>
                  <caption class="hide">
                    항목
                  </caption>
                  <colgroup>
                    <col width="127px" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <v-badge dot color="#EF9191" v-if="dataFlag === 'I'">
                          건설사
                        </v-badge>
                        <label v-else>
                          건설사
                        </label>
                      </th>
                      <td>
                        <div class="form-inp full">
                          <v-select
                            v-model="CTCP_CD"
                            :items="detailDropCtcpCd"
                            item-text="CD_NM"
                            item-value="CD"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                            :disabled="ctcpCd"
                            @change="changeDetailBussCode(CTCP_CD)"
                          >
                          </v-select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <v-badge dot color="#EF9191" v-if="dataFlag === 'I'">
                          사업지명
                        </v-badge>
                        <label v-else>
                          사업지명
                        </label>
                      </th>
                      <td>
                        <div class="form-inp full">
                          <v-text-field v-if="dataFlag === 'U'"
                            class="form-inp full"
                            v-model="BUSS_NAME"
                            name="BUSS_NAME"
                            outlined
                            hide-details
                            :disabled="bussName"
                          >
                          </v-text-field>
                          <v-select v-else
                            v-model="BUSS_CODE"
                            :items="detailDropBussCd"
                            item-text="CD_NM"
                            item-value="CD"
                            outlined
                            hide-details
                            hide-no-data
                            placeholder="선택하세요"
                            :disabled="bussCode"
                          >
                          </v-select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <v-badge dot color="#EF9191" v-if="dataFlag === 'I' || dataFlag === 'U'">
                          약관제목
                        </v-badge>
                        <label v-else>
                          약관제목
                        </label>
                      </th>
                      <td>
                        <v-text-field
                          class="form-inp full"
                          v-model="TERM_TITLE"
                          name="TERM_TITLE"
                          outlined
                          hide-details
                          :disabled="termTitle"
                        >
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        약관내용
                      </th>
                      <td>
                        <v-textarea
                          class="textarea-type0"
                          v-model="TERM_CONT"
                          name="TERM_CONT"
                          counter
                          no-resize
                          outlined
                          hide-details
                          height="150px"
                          :disabled="termCont"
                        >
                        </v-textarea>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <v-badge dot color="#EF9191" v-if="dataFlag === 'I' || dataFlag === 'U'">
                          상태
                        </v-badge>
                        <label v-else>
                          상태
                        </label>
                      </th>
                      <td>
                        <div class="form-inp full">
                          <v-select
                            v-model="USE_YN"
                            :items="dropUseYn"
                            item-text="CD_NM"
                            item-value="CD"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                            :disabled="useYn"
                          >
                          </v-select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        버전
                      </th>
                      <td>
                        <v-text-field
                          class="form-inp full"
                          v-model="TERM_VRSN"
                          name="TERM_VRSN"
                          type="number"
                          min="1"
                          max="9999"
                          outlined
                          hide-details
                          :disabled="termVrsn"
                        >
                        </v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        타입
                      </th>
                      <td>
                        <div class="form-inp full">
                          <v-select
                            v-model="TERM_TYPE_CD"
                            :items="dropTermTypeCd"
                            item-text="CD_NM"
                            item-value="CD"
                            outlined
                            hide-details
                            placeholder="선택하세요"
                            :disabled="termTypeCd"
                          >
                          </v-select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>  
            </v-form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: "MENU_BUM0200", //name은 'MENU_' + 파일명 조합
    components: {
    },
    data() {
      return {
        HEADERResult: '',
        DATAResult  : '',
        
        HEADER_TYPE     : 'BIZ_SERVICE',
        HEADER_GRID_ID  : '',
        HEADER_ROW_CNT  : 0,
        HEADER_PAGES_CNT: 0,
        headers         : {},
        
        URLData : '',
        sendData: {},
        
        // 공통코드
        groupCdList  : ['HLW_CTCP_CD', 'HLW_USE_YN', 'HLW_TERM_TYPE_CD'],
        allCodeList  : [],
        USER_AUTH_CHK: '', // 사용자 권한 체크

        // 조회
        CTCP_CODE        : '',
        BUSS_CD          : '',
        TERM_TITLE_SEARCH: '',

        // select box
        dropCtcpCd      : [], // 건설사명
        dropBussCd      : [], // 사업지명
        detailDropCtcpCd: [], // 상세건설사명
        detailDropBussCd: [], // 상세사업지명
        dropUseYn       : [], // 사용여부
        dropTermTypeCd  : [], // 타입

        // 약관 목록
        termGridDataHeaders: [
          { text: 'No', value: 'index', align: 'center', width: '50px', sortable: false, },
          { text: "사업지명", value: 'BUSS_NAME', align: 'center', width: '250px', sortable: false, },
          { text: "약관제목", value: 'TERM_TITLE', align: 'center', width: '250px', sortable: false, },
          { text: "사용여부", value: 'USE_YN_NAME', align: 'center', width: '80px', sortable: false, },
          { text: "약관버전", value: 'TERM_VRSN', align: 'center', width: '80px', sortable: false, },
          { text: "약관유형", value: 'TERM_TYPE_NAME', align: 'center', width: '120px', sortable: false, },
          { text: "약관ID", value: 'TERM_ID', align: ' d-none', width: '0px', sortable: false, },
          { text: "건설사코드", value: 'CTCP_CD', align: ' d-none', width: '0px', sortable: false, },
          { text: "건설사명", value: 'CTCP_NAME', align: ' d-none', width: '0px', sortable: false, },
          { text: "사업지코드", value: 'BUSS_CODE', align: ' d-none', width: '0px', sortable: false, },
          { text: "약관유형코드", value: 'TERM_TYPE_CD', align: ' d-none', width: '0px', sortable: false, },
          { text: "약관내용", value: 'TERM_CONT', align: ' d-none', width: '0px', sortable: false, },
          { text: "사용여부코드", value: 'USE_YN', align: ' d-none', width: '0px', sortable: false, },
        ],
        termGridDataText        : [], // 사업지 목록 데이터
        selectedTermGridDataList: [],

        // 엑셀다운로드
        excelDataHeaders: [
          { text: "건설사명", value: 'CTCP_NAME', align: 'center', width: '250px', sortable: false, },
          { text: "사업지명", value: 'BUSS_NAME', align: 'center', width: '250px', sortable: false, },
          { text: "약관제목", value: 'TERM_TITLE', align: 'center', width: '250px', sortable: false, },
          { text: "약관내용", value: 'TERM_CONT', align: 'center', width: '250px', sortable: false, },
          { text: "사용여부", value: 'USE_YN_NAME', align: 'center', width: '80px', sortable: false, },
          { text: "약관버전", value: 'TERM_VRSN', align: 'center', width: '80px', sortable: false, },
          { text: "약관유형", value: 'TERM_TYPE_NAME', align: 'center', width: '120px', sortable: false, },
        ],
        EXCEL_BUSS_NAME: '',

        // 약관 상세
        CTCP_CD     : '', // 건설사코드
        BUSS_CODE   : '', // 사업지코드
        BUSS_NAME   : '', // 사업지명
        TERM_TITLE  : '', // 약관제목
        TERM_CONT   : '', // 약관내용
        USE_YN      : '', // 사용여부
        TERM_VRSN   : '', // 버전
        TERM_TYPE_CD: '', // 타입
        TERM_ID     : '', // 약관ID
        
        // 약관 상세 비활성화
        ctcpCd    : true,
        bussCode  : true,
        bussName  : true,
        termTitle : true,
        termCont  : true,
        useYn     : true,
        termVrsn  : true,
        termTypeCd: true,

        // 버튼 비활성화
        delBtn  : true,
        saveBtn : true,
        copyBtn : true,
        excelBtn: true,

        // 기타
        USER_ID  : '', // 사용자아이디
        dataFlag : '', // 처리구분(I: 등록, U: 수정, D: 삭제)
        btnName  : '신규', // 버튼명
        copyYnChk: '', // 복사 여부 체크(chk: 복사)

        // 메시지
        bum0200Msg: {
          saveChk           : '약관을 등록하시겠습니까?',
          editChk           : '약관을 수정하시겠습니까?',
          copyChk           : '약관을 복사하시겠습니까?',
          deleteChk         : '약관을 삭제하시겠습니까?',
          saveSuccess       : '저장되었습니다.',
          editSuccess       : '수정되었습니다.',
          deleteSuccess     : '삭제되었습니다.',
          copySuccess       : '복사되었습니다.',
          ctcrCdEmptyChk    : '건설사를 선택하세요.',
          bussNameEmptyChk  : '사업지명을 선택하세요.',
          termTitleEmptyChk : '약관제목을 입력하세요.',
          termContEmptyChk  : '약관내용을 입력하세요.',
          useYnEmptyChk     : '상태를 선택하세요.',
          termVrsnEmptyChk  : '버전을 입력하세요.',
          termVrsnChk       : '버전은 1부터 입력 가능합니다.',
          termTypeCdEmptyChk: '타입을 선택하세요.',
        },

        // 페이징
        page        : 1,
        pageCount   : 0,
        itemsPerPage: 30,
        totalVisible: 10,
      };
    },
    methods: {
      // 약관관리 조회
      async getTermList() {
        this.listClean();

        let requestData = {
          headers: this.initHeaders,
          sendData: {},
        }

        requestData.headers["URL"] = "/api/buss/term-manage/list";
        requestData.headers["SERVICE"] = "buss.term-manage";
        requestData.headers["METHOD"] = "list";
        requestData.headers["TWB_SQL_ID"] = "selectTermList";
        requestData.headers["ASYNC"] = false;
        requestData.headers["GRID_ID"] = "termGridDataHeaders";
        
        requestData.sendData["CTCP_CD"] = this.CTCP_CODE;
        requestData.sendData["BUSS_CODE"] = this.BUSS_CD;
        requestData.sendData["TERM_TITLE_SEARCH"] = this.TERM_TITLE_SEARCH;

        let response = await this.common_postCall(requestData);

        this.result(response);
      },

      // 약관관리 조회 결과
      getGridDataCallBack(response) {
        this.isActiveTermRow('');

        let data = response.DATA;
        this.termGridDataText = data;

        if(this.mixin_isEmpty(this.CTCP_CODE) && this.mixin_isEmpty(this.BUSS_CD) && this.termGridDataText.length !== 0) {
          this.excelBtn = false;
          this.EXCEL_BUSS_NAME = ''; // 이전 내용 초기화
          this.EXCEL_BUSS_NAME = '전체';
        }else if(!this.mixin_isEmpty(this.CTCP_CODE) && this.mixin_isEmpty(this.BUSS_CD) && this.termGridDataText.length !== 0) {
          this.excelBtn = false;
          this.EXCEL_BUSS_NAME = ''; // 이전 내용 초기화
          this.EXCEL_BUSS_NAME = this.decode(this.termGridDataText[0]["CTCP_NAME"]);
        }else if(!this.mixin_isEmpty(this.CTCP_CODE) && !this.mixin_isEmpty(this.BUSS_CD) && this.termGridDataText.length !== 0) {
          this.excelBtn = false;
          this.EXCEL_BUSS_NAME = ''; // 이전 내용 초기화
          this.EXCEL_BUSS_NAME = this.decode(this.termGridDataText[0]["BUSS_NAME"]);
        }else {
          this.excelBtn = true;
        }

        // 그리드 데이터 가공
        let idx = 1;
        let dataText = this.termGridDataText;

        for (let i in dataText) {
          this.termGridDataText[i]["index"] = idx++;
          this.termGridDataText[i]["TERM_TITLE"] = this.decode(this.termGridDataText[i].TERM_TITLE);
          this.termGridDataText[i]["TERM_CONT"] = this.decode(this.termGridDataText[i].TERM_CONT);
        }
      },

      // 약관관리 목록 클릭 - 약관 상세 조회
      async termGridRowClick(item) {
        this.TERM_ID = item.TERM_ID;
        this.dataFlag = 'U';
        this.isActive('U');
        this.btnName = '신규';

        if(this.termGridDataText.length !== 0) {
          this.excelBtn = false;
        }else if(this.CTCP_CODE !== '' && this.BUSS_CD !== '' && this.termGridDataText.length !== 0) {
          this.excelBtn = false;
        }

        this.changeDetailBussCode(item.CTCP_CD);

        this.CTCP_CD = item.CTCP_CD;
        this.BUSS_CODE = item.BUSS_CODE;
        this.BUSS_NAME = item.BUSS_NAME;
        this.TERM_TITLE = this.decode(item.TERM_TITLE);
        this.TERM_CONT = this.decode(item.TERM_CONT);
        this.USE_YN = item.USE_YN;
        this.TERM_VRSN = item.TERM_VRSN;
        this.TERM_TYPE_CD = item.TERM_TYPE_CD;
      },

      // 약관 등록
      async newAdd() {
        this.dataFlag = this.dataFlag === 'I' ? '' : 'I';
        this.dataFlag === 'I' ? this.isActive('I') : this.isActive(); // 활성화 여부
        this.btnName = this.dataFlag === 'I' ? '취소' : '신규';
        this.copyYnChk = this.dataFlag === 'I' ? this.copyYnChk : '';

        if(this.dataFlag === 'I' && this.termGridDataText.length !== 0) {
          this.excelBtn = false;
        }else if(this.dataFlag === 'I' && this.CTCP_CODE !== '' && this.BUSS_CD !== '' && this.termGridDataText.length !== 0) {
          this.excelBtn = false;
        }

        if(this.copyYnChk === 'chk') {
          this.common_alert(this.bum0200Msg.copySuccess);
          
          if (this.USER_AUTH_CHK === 'SYSTEMMANAGER') {
            // 시스템관리자인 경우
            this.detailDropBussCd = await this.mixin_busscode_get(this.CTCP_CD);
          }else {
            this.detailDropBussCd = await this.mixin_busscode_get(this.CTCP_CD, this.USER_ID);
          }
          this.bussCode = this.dataFlag === 'I' && this.detailDropBussCd.length !== 0 ? false : true;
        }else {
          this.TERM_ID = '';
          this.detailDropBussCd = [];
          this.cleanData();

          if(this.CTCP_CODE !== '' && this.BUSS_CD !== '') {
            // 조회 후 신규 작성 시 조회 조건 반영
            this.CTCP_CD = this.dataFlag === 'I' ? this.CTCP_CODE : '';
            this.changeDetailBussCode(this.CTCP_CD);
          }
        }
      },

      // 약관 등록/수정 여부 확인
      newBtnSave() {
        // 유효성 체크
        if(!this.saveValidate()) {
          return;
        }

        if(this.dataFlag === 'I') {
          this.common_confirm(this.bum0200Msg.saveChk, this.newEditSave, '', null, null, "chk");
        }else if(this.dataFlag === 'U') {
          this.common_confirm(this.bum0200Msg.editChk, this.newEditSave, '', null, null, "chk");
        }
      },

      // 약관 등록/수정 저장
      async newEditSave() {
        let requestData = {
          headers: this.initHeaders,
          sendData: {},
        };

        requestData.headers["URL"] = "/api/buss/term-manage/regist";
        requestData.headers["SERVICE"] = "buss.term-manage";
        requestData.headers["METHOD"] = "regist";
        requestData.headers["ASYNC"] = false;

        if(this.dataFlag === 'I') {
          requestData.headers["TWB_SQL_ID"] = "insertTermNewInfo";

          requestData.sendData["DATA_FLAG"] = this.dataFlag;
          requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
          requestData.sendData["BUSS_NAME"] = this.BUSS_NAME;
          requestData.sendData["TERM_TITLE"] = this.TERM_TITLE;
          requestData.sendData["TERM_CONT"] = this.encode(this.TERM_CONT);
          requestData.sendData["USE_YN"] = this.USE_YN;
          requestData.sendData["TERM_VRSN"] = Number(this.TERM_VRSN);
          requestData.sendData["TERM_TYPE_CD"] = this.TERM_TYPE_CD;
          requestData.sendData["REG_ID"] = this.USER_ID;
          requestData.sendData["UPD_ID"] = this.USER_ID;
        }else if(this.dataFlag === 'U') {
          requestData.headers["TWB_SQL_ID"] = "updateTermInfo";

          requestData.sendData["TERM_ID"] = this.TERM_ID;
          requestData.sendData["DATA_FLAG"] = this.dataFlag;
          requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
          requestData.sendData["TERM_TITLE"] = this.TERM_TITLE;
          requestData.sendData["TERM_CONT"] = this.encode(this.TERM_CONT);
          requestData.sendData["USE_YN"] = this.USE_YN;
          requestData.sendData["TERM_VRSN"] = Number(this.TERM_VRSN);
          requestData.sendData["TERM_TYPE_CD"] = this.TERM_TYPE_CD;
          requestData.sendData["UPD_ID"] = this.USER_ID;
        }

        let response = await this.common_postCall(requestData);

        this.result(response);
      },

      // 약관 등록/수정 저장 결과
      getGridDataSaveCallBack() {
        if(this.dataFlag === 'I') {
          this.common_alert(this.bum0200Msg.saveSuccess);
        }else if(this.dataFlag === 'U') {
          this.common_alert(this.bum0200Msg.editSuccess);
        }
        this.btnName = '신규';
        this.dataFlag = '';
        this.copyYnChk = '';
        this.getTermList();
        this.isActive();
        this.cleanData();
      },

      // 약관 삭제 여부 확인
      delInfo() {
        this.dataFlag = 'D';
        
        if(this.dataFlag === 'D') {
          this.common_confirm(this.bum0200Msg.deleteChk, this.termDel, '', null, null, "chk");
        }
      },

      // 약관 삭제
      async termDel() {
        let requestData = {
          headers: this.initHeaders,
          sendData: {},
        };

        requestData.headers["URL"] = "/api/buss/term-manage/delete";
        requestData.headers["SERVICE"] = "buss.term-manage";
        requestData.headers["METHOD"] = "delete";
        requestData.headers["TWB_SQL_ID"] = "deleteTermInfo";
        requestData.headers["ASYNC"] = false;

        requestData.sendData["BUSS_CODE"] = this.BUSS_CODE;
        requestData.sendData["TERM_ID"] = this.TERM_ID;

        let response = await this.common_postCall(requestData);

        this.result(response);
      },

      // 약관 삭제 결과
      getGridDataDeleteCallBack() {
        this.common_alert(this.bum0200Msg.deleteSuccess);
        this.dataFlag = '';
        this.getTermList();
        this.isActive();
        this.cleanData();
      },

      // 결과 확인
      result(response) {
        if(response.HEADER.METHOD === "list" && response.HEADER.TWB_SQL_ID === "selectTermList") {
          this.getGridDataCallBack(response);
        }else if(response.HEADER.METHOD === "regist") {
          this.getGridDataSaveCallBack();
        }else if(response.HEADER.METHOD === "delete" && response.HEADER.TWB_SQL_ID === "deleteTermInfo") {
          this.getGridDataDeleteCallBack();
        }
      },

      // 약관 복사 여부 확인
      copyInfo() {
        if(this.dataFlag === 'U') {
          this.copyYnChk = 'chk';
          this.common_confirm(this.bum0200Msg.copyChk, this.newAdd, '', null, null, "chk");
        }
      },

      // 엑셀다운로드
      downloadExcel(fileNm, tableId ){
        let fileName = this.EXCEL_BUSS_NAME + '_' + fileNm;
        this.table_id = tableId;

        this.mixin_common_Excel(this.excelDataHeaders, this.termGridDataText, fileName, this.EXCEL_BUSS_NAME);
      },

      // 목록 선택 시 색상 표시
      isActiveTermRow(item) {
        return item.TERM_ID === this.TERM_ID ? 'active' : '';
      },

      // 목록 초기화
      listClean() {
        this.isActive();
        this.cleanData();
        this.termGridDataText = [];
        this.dataFlag = '';
        this.btnName = '신규';
        this.TERM_ID = '';
      },

      // 상세 초기화
      cleanData() {
        this.BUSS_CODE = '';
        this.BUSS_NAME = '';
        this.CTCP_CD = '';
        this.TERM_TITLE = '';
        this.TERM_CONT = '';
        this.USE_YN = '';
        this.TERM_VRSN = '';
        this.TERM_TYPE_CD = '';
      },
      
      // 사업지 코드 가져오기
      async changeBussCode(ctcpCode) {
        if(ctcpCode !== '') {
          this.BUSS_CD = '';
          this.dropBussCd = [];
          this.dropBussCd.push({ CD: '', CD_NM: '' });
          
          if(this.USER_AUTH_CHK === 'SYSTEMMANAGER') {
            // 시스템관리자인 경우
            this.dropBussCd = await this.mixin_busscode_get(ctcpCode);
          }else {
            this.dropBussCd = await this.mixin_busscode_get(ctcpCode, this.USER_ID);
            this.BUSS_CD = this.dropBussCd[0].CD;
          }
        }else {
          this.BUSS_CD = '';
          this.dropBussCd = [];
        }
      },

      // 약관 상세 사업지 코드 가져오기
      async changeDetailBussCode(ctcpCode) {
        if(ctcpCode !== '') {
          this.BUSS_CODE = '';
          this.detailDropBussCd = [];
          this.detailDropBussCd.push({ CD: '', CD_NM: '' });
          
          this.detailDropBussCd = await this.mixin_busscode_get(ctcpCode);
          
          for(let i = 0; i < this.detailDropBussCd.length; i++) {
            if(this.detailDropBussCd[i].CD === this.BUSS_CD) {
              this.BUSS_CODE = this.detailDropBussCd[i].CD;
            }
          }
          this.bussCode = this.dataFlag === 'I' && this.detailDropBussCd.length !== 0 ? false : true;
        }else {
          this.BUSS_CODE = '';
          this.detailDropBussCd = [];
        }
      },

      // 유효성 체크
      saveValidate() {
        if(this.mixin_isEmpty(this.CTCP_CD)) {
          this.common_alert(this.bum0200Msg.ctcrCdEmptyChk, "chk");
          return;
        }

        if(this.mixin_isEmpty(this.BUSS_CODE)) {
          this.common_alert(this.bum0200Msg.bussNameEmptyChk, "chk");
          return;
        }

        if(this.mixin_isEmpty(this.TERM_TITLE)) {
          this.common_alert(this.bum0200Msg.termTitleEmptyChk, "chk");
          return;
        }

        if(this.dataFlag === 'I' && this.copyYnChk === '') {
          if(this.mixin_isEmpty(this.USE_YN)) {
            this.common_alert(this.bum0200Msg.useYnEmptyChk, "chk");
            return;
          }
        }

        if(this.mixin_isEmpty(this.TERM_VRSN) && this.TERM_VRSN === 0) {
          this.common_alert(this.bum0200Msg.termVrsnChk, "chk");
          return;
        }
        return true;
      },

      // 약관 상세 활성화 및 비활성화
      isActive(item) {
        if(item === 'I') {
          // 약관 신규
          this.ctcpCd = false;      // 건설사코드
          this.bussCode = true;     // 사업지코드
          this.bussName = false;    // 사업지명
          this.termTitle = false;   // 약관제목
          this.termCont = false;    // 약관내용
          this.useYn = false;       // 사용여부
          this.termVrsn = false;    // 버전
          this.termTypeCd = false;  // 타입
          this.delBtn = true;       // 삭제버튼
          this.saveBtn = false;     // 저장버튼
          this.copyBtn = true;      // 복사버튼
        }else if(item === 'U') {
          // 약관 수정
          this.ctcpCd = true;       // 건설사코드
          this.bussCode = true;     // 사업지코드
          this.bussName = true;     // 사업지명
          this.termTitle = false;   // 약관제목
          this.termCont = false;    // 약관내용
          this.useYn = false;       // 사용여부
          this.termVrsn = false;    // 버전
          this.termTypeCd = false;  // 타입
          this.delBtn = false;      // 삭제버튼
          this.saveBtn = false;     // 저장버튼
          this.copyBtn = false;     // 복사버튼
        }else {
          // 조회
          this.ctcpCd = true;       // 건설사코드
          this.bussCode = true;     // 사업지코드
          this.bussName = true;     // 사업지명
          this.termTitle = true;    // 약관제목
          this.termCont = true;     // 약관내용
          this.useYn = true;        // 사용여부
          this.termVrsn = true;     // 버전
          this.termTypeCd = true;   // 타입
          this.delBtn = true;       // 삭제버튼
          this.saveBtn = true;      // 저장버튼
          this.copyBtn = true;      // 복사버튼
        }
      },

      /**
      * XSS Encode
      */
      encode(strText) {
        if (strText === "" || strText === null) {
          return strText;
        }
        //strText = strText.toString();

        strText = strText.replaceAll("(", "&#40;");
        strText = strText.replaceAll(")", "&#41;");
        strText = strText.replaceAll("[", "&#91;");
        strText = strText.replaceAll("]", "&#93;");
        strText = strText.replaceAll("&", "&amp;");
        strText = strText.replaceAll('"', "&quot;");
        strText = strText.replaceAll("'", "&apos;");
        strText = strText.replaceAll("\r", "<br>");
        strText = strText.replaceAll("\n", "<p>");
        strText = strText.replaceAll("<", "&lt;");
        strText = strText.replaceAll(">", "&gt;");

        return strText;
      },

      /**
      * XSS Decode
      */
      decode(strText) {

        //값존재유무 체크
        if (strText === "" || strText === null) {
          return strText;
        }
        strText = strText.toString();

        //문자열 길이가 4이상일 경우만 처리
        if (strText.length <= 3) {
          return strText;
        }

        //순서바뀌면 안나옴
        strText = strText.replaceAll("&lt;", "<");
        strText = strText.replaceAll("&gt;", ">");
        strText = strText.replaceAll("&amp;", "&");
        strText = strText.replaceAll("&quot;", '"');
        strText = strText.replaceAll("&apos;", "'");
        strText = strText.replaceAll("<br>", "\r");
        strText = strText.replaceAll("<p>", "\n");
        strText = strText.replaceAll("&#91;", "[");
        strText = strText.replaceAll("&#93;", "]");
        strText = strText.replaceAll("&#40;", "(");
        strText = strText.replaceAll("&#41;", ")");

        return strText;
      },
    },
    async mounted() {
      this.USER_ID = this.user_id;
      this.allCodeList = await this.mixin_common_code_get_all(this.groupCdList);

      let atrtGroupNm = this.user_role.atrtGroupNm;
      
      if(atrtGroupNm === '시스템관리자') {
        this.USER_AUTH_CHK = 'SYSTEMMANAGER';  
        this.dropCtcpCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD', '전체');
        this.dropUseYn = await this.mixin_common_code_get(this.allCodeList, 'HLW_USE_YN');
        this.dropTermTypeCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_TERM_TYPE_CD');
        this.detailDropCtcpCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_CTCP_CD');
      }else {
        let usserAuth = await this.mixin_buss_ctcp_code_user_auto(this.USER_ID);

        this.dropCtcpCd = [{ CD_NM: usserAuth[0].CTCP_NAME, CD: usserAuth[0].CTCP_CODE }];
        this.dropUseYn = await this.mixin_common_code_get(this.allCodeList, 'HLW_USE_YN');
        this.dropTermTypeCd = await this.mixin_common_code_get(this.allCodeList, 'HLW_TERM_TYPE_CD');
        this.detailDropCtcpCd = [{ CD_NM: usserAuth[0].CTCP_NAME, CD: usserAuth[0].CTCP_CODE }];
        this.CTCP_CODE = this.dropCtcpCd[0].CD;
        this.changeBussCode(this.CTCP_CODE);
      }
      this.isActive(); // 비활성화
    },
    computed: {
      initHeaders() {
        return this.headers = {
          "SERVICE": "",
          "METHOD": "",
          "TWB_SQL_ID": "",
          "TYPE": this.HEADER_TYPE,
        };
      },
    },
  };
</script>
<style>

</style>
